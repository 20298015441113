.Loader {
  &.is-small {
    .lds-ring {
      width: 40px;
      height: 40px;
    }

    .lds-ring div {
      width: 32px;
      height: 32px;
    }
    .description {
      font-size: 0.75rem;
    }
  }
  &.is-white {
    .lds-ring div {
      border-color: #fff transparent transparent transparent;
    }

    .description {
      color: white;
    }
  }

  .description {
    text-align: center;
  }
  .lds-ring {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #3949ab;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3949ab transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
