.ImportPage-Form {
  .container {
    width: 100%;
  }

  .table-container {
    max-height: 500px;
    overflow-y: scroll;

    .table {
      thead tr:nth-child(1) th {
        position: sticky;
        background: white;
        top: 0;
        z-index: 10;
      }
    }
  }
}
