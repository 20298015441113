@import "~bulma/bulma.sass";
@import "~@creativebulma/bulma-collapsible";
@import "~bulma-switch/dist/css/bulma-switch.min.css";

html,
body {
  background-color: #3949ab;
}

body {
  padding-top: 30px;
}

.panel {
  background-color: #f9f9f9;
}

.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 1px 5px #333;
}

.dropzone > * {
  text-align: center;
  padding: 20px 15px;
  border: 3px dashed #d9d9d9;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  transform: translate(-50%, -50%);
}

.panel-heading {
  align-items: center;
  p {
    flex: 2;
  }
  .search {
    flex: 1;
    align-self: center;
    justify-self: flex-end;
    margin-bottom: 0;
    margin-right: 5px;
  }
}

button.is-link-like {
  background: transparent;
  border: none;
}

.album {
  .box {
    transition: background-color 0.25s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: darken($box-background-color, 5%);
    }
  }
}

.CodeViewerPopup {
  h1.subtitle,
  h2.description {
    font-size: 1.5rem;
    margin: 0;
  }

  ul {
    list-style-type: none;
    li {
      text-align: left;
    }
  }

  a {
    display: block;
    padding-top: 10px;
    font-weight: bold;
  }
}

.hover-pointer:hover {
  cursor: pointer;
}
