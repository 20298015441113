.ViewAlbumPage {
  .dropzone > * {
    text-align: center;
    padding: 20px 15px;
    border: 3px dashed #d9d9d9;
  }
  .album-art img {
    max-width: 256px;
    margin: 0 auto;
  }

  .icon:hover {
    cursor: pointer;
  }

  .tracks.to-be-added,
  .tracks.to-be-added > p {
    margin: 0.5em 0;
  }

  .processing-loader {
    margin-right: 20px;
  }

  .tracks,
  .files {
    max-height: 800px;
    overflow: scroll;
    padding-bottom: 20px;
  }

  .track,
  .file {
    display: block;
    max-width: 100%;
    .level-left {
      max-width: 90%;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
