.track {
  overflow: hidden;
  &.box {
    padding: 0.5rem;
  }

  &:hover {
    cursor: grab;
  }
  margin-bottom: 0.5rem;

  .actions {
    * {
      margin-left: 0.5rem;
    }
  }
}
