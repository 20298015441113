.CodeList {
  .card-header {
    align-items: center;
    a {
      display: flex;
      flex: 1;
      align-items: center;
    }

    .card-header-title {
      flex: 1;
    }

    .stats {
      flex: 2;
    }

    .icons {
      display: flex;
      padding-right: 25px;

      & > *:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .sorting {
    padding: 10px;

    label:nth-child(2) {
      margin-right: 30px;
    }
  }
}
