.ColorPicker {
  position: relative;

  .color-picker-popup {
    position: absolute !important;
    z-index: 100000;
    display: none;

    &.is-visible {
      display: block;
    }
  }

  input[name="color"] {
    &.is-light {
      color: #333 !important;
    }
    &.is-dark {
      color: #fff !important;
    }
  }
}
